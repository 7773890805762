var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"assessemnt__warning"},[_c('SBadge',{attrs:{"variation":"warning"}},[_c('Info',{attrs:{"icon":{
          name: 'info',
          color: '#78809A',
          size: '20px',
        },"label":"A aplicação das provas segue o horário oficial de Brasília."}})],1)],1),_c('transition-group',{staticClass:"assessment__exams",attrs:{"id":"v-step-1","appear":"","name":"card","tag":"div"}},[_vm._l((_vm.examsWithLanguage),function(examItem){return _c('Box',{key:examItem.id,staticClass:"exam"},[_c('div',{class:[
          'exam__details',
          {
            '--flex-start': _vm.isExamDone(examItem) || examItem.completed,
            '--exam-done': _vm.isExamDone(examItem),
          } ]},[_c('h3',[_vm._v(" "+_vm._s(examItem.name))]),(_vm.hasBadge(examItem))?_c('SBadge',{staticClass:"exam__badge",attrs:{"variation":_vm.getBadge(examItem).status}},[_vm._v(" "+_vm._s(_vm.getBadge(examItem).text)+" ")]):_vm._e(),(!_vm.isExamDone(examItem))?_c('div',{staticClass:"exam__details__infos"},_vm._l((_vm.getExamDetails(examItem)),function(detail,index){return _c('div',{key:index,attrs:{"id":"v-step-2"}},[_c('Info',{staticClass:"exam__details__infos__label",attrs:{"icon":{
                name: detail.icon,
                color: '#999EA3',
              },"label":detail.text}})],1)}),0):_vm._e(),_c('div',{staticClass:"box__footer"},[(_vm.languages && _vm.isForeignLanguageVisible(examItem))?_c('div',{staticClass:"assessment-filter-box-subgrid"},[(!_vm.isLoading && _vm.getExamAction(examItem).text)?_c('dropdown',{attrs:{"disabled":!_vm.isExamAvaiable(examItem),"text":_vm.getSelectedLanguageName(examItem)}},_vm._l((_vm.languages),function(language){return _c('dropdown-menu-item',{key:language.code,on:{"click":function($event){return _vm.selectLanguage(examItem, language)}}},[_vm._v(" "+_vm._s(language.name)+" ")])}),1):_vm._e()],1):_vm._e(),(_vm.getExamAction(examItem).visible)?_c('s-button',{staticClass:"box__action",attrs:{"disabled":_vm.getExamAction(examItem).disabled,"icon-right":_vm.getExamAction(examItem).icon,"size":"large"},on:{"click":function($event){return _vm.executeAction(examItem)}}},[_vm._v(" "+_vm._s(_vm.getExamAction(examItem).text)+" ")]):_vm._e()],1)],1),(_vm.isResultDateInfoVisible(examItem))?_c('Info',{staticClass:"exam__warning",attrs:{"icon":{
          name: 'info',
          color: '#999EA3',
        },"label":("Seu resultado detalhado estará disponível no dia " + _vm.resultDate + "."),"variation":"neutral"}}):_vm._e()],1)}),(_vm.hasEssay && _vm.languages)?_c('Box',{key:"0",attrs:{"action":{
        text: 'Visualizar tema da redação',
        disabled: false,
      }}},[_c('div',{staticClass:"exam__details"},[_c('h3',[_vm._v(" "+_vm._s(_vm.$t('pages.assessmentsExams.essay'))+" ")]),_c('SBadge',{staticClass:"exam__badge",attrs:{"variation":"success"}},[_vm._v(" "+_vm._s(_vm.$t('pages.assessmentsExams.available'))+" ")]),_c('div',{staticClass:"exam__details__infos"},[_c('Info',{staticClass:"exam__details__infos__label",attrs:{"icon":{
              name: 'file-text',
              color: _vm.$tokens.color_ink_lighter,
            },"label":_vm.$t('pages.assessmentsExams.essayDetails')}}),_c('Info',{staticClass:"exam__details__infos__label",attrs:{"icon":{
              name: 'align-center',
              color: _vm.$tokens.color_ink_lighter,
            },"label":_vm.$t('pages.assessmentsExams.numberLines', [7, 30])}})],1),_c('s-button',{staticClass:"box__action",attrs:{"size":"large"},on:{"click":function($event){return _vm.openEssayTheme()}}},[_vm._v(" "+_vm._s(_vm.$t('pages.assessmentsExams.viewEssayTheme'))+" ")])],1)]):_vm._e()],2),(_vm.isTourActive)?_c('TourAssessmentExams',{attrs:{"application":_vm.application},on:{"next":function($event){_vm.isNextButtonPressed = true},"previous":function($event){_vm.isPreviousButtonPressed = true},"skip":function($event){return _vm.skipTour()}}}):_vm._e(),(_vm.isModalVisible && !_vm.hasDuration)?_c('AssessmentExamModal',{attrs:{"exam":_vm.exam},on:{"close":function($event){return _vm.hideExamModal()},"start":function($event){return _vm.start($event)}}}):_vm._e(),(_vm.isModalVisible && _vm.hasDuration)?_c('AssessmentExamModal',{attrs:{"exam":_vm.exam},on:{"close":function($event){return _vm.hideExamModal()},"start":function($event){return _vm.start($event)}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }