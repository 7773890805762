<template>
  <div>
    <div class="assessemnt__warning">
      <SBadge
        variation="warning"
      >
        <Info
          :icon="{
            name: 'info',
            color: '#78809A',
            size: '20px',
          }"
          label="A aplicação das provas segue o horário oficial de Brasília."
        />
      </Sbadge>
    </div>

    <transition-group
      id="v-step-1"
      appear
      class="assessment__exams"
      name="card"
      tag="div"
    >
      <Box
        v-for="examItem in examsWithLanguage"
        :key="examItem.id"
        class="exam"
      >
        <div
          :class="[
            'exam__details',
            {
              '--flex-start': isExamDone(examItem) || examItem.completed,
              '--exam-done': isExamDone(examItem),
            },
          ]"
        >
          <h3> {{ examItem.name }}</h3>

          <SBadge
            v-if="hasBadge(examItem)"
            class="exam__badge"
            :variation="getBadge(examItem).status"
          >
            {{ getBadge(examItem).text }}
          </Sbadge>

          <div
            v-if="!isExamDone(examItem)"
            class="exam__details__infos"
          >
            <div
              v-for="(detail, index) in getExamDetails(examItem)"
              id="v-step-2"
              :key="index"
            >
              <Info
                class="exam__details__infos__label"
                :icon="{
                  name: detail.icon,
                  color: '#999EA3',
                }"
                :label="detail.text"
              />
            </div>
          </div>
          <div class="box__footer">
            <div
              v-if="languages && isForeignLanguageVisible(examItem)"
              class="assessment-filter-box-subgrid"
            >
              <dropdown
                v-if="!isLoading && getExamAction(examItem).text"
                :disabled="!isExamAvaiable(examItem)"
                :text="getSelectedLanguageName(examItem)"
              >
                <dropdown-menu-item
                  v-for="language in languages"
                  :key="language.code"
                  @click="selectLanguage(examItem, language)"
                >
                  {{ language.name }}
                </dropdown-menu-item>
              </dropdown>
            </div>

            <s-button
              v-if="getExamAction(examItem).visible"
              class="box__action"
              :disabled="getExamAction(examItem).disabled"
              :icon-right="getExamAction(examItem).icon"
              size="large"
              @click="executeAction(examItem)"
            >
              {{ getExamAction(examItem).text }}
            </s-button>
          </div>
        </div>

        <Info
          v-if="isResultDateInfoVisible(examItem)"
          class="exam__warning"
          :icon="{
            name: 'info',
            color: '#999EA3',
          }"
          :label="
            `Seu resultado detalhado estará disponível no dia ${resultDate}.`"
          variation="neutral"
        />
      </Box>

      <Box
        v-if="hasEssay && languages"
        key="0"
        :action="{
          text: 'Visualizar tema da redação',
          disabled: false,
        }"
      >
        <div
          class="exam__details"
        >
          <h3>
            {{ $t('pages.assessmentsExams.essay') }}
          </h3>

          <SBadge
            class="exam__badge"
            variation="success"
          >
            {{ $t('pages.assessmentsExams.available') }}
          </SBadge>

          <div class="exam__details__infos">
            <Info
              class="exam__details__infos__label"
              :icon="{
                name: 'file-text',
                color: $tokens.color_ink_lighter,
              }"
              :label="$t('pages.assessmentsExams.essayDetails')"
            />
            <Info
              class="exam__details__infos__label"
              :icon="{
                name: 'align-center',
                color: $tokens.color_ink_lighter,
              }"
              :label="$t('pages.assessmentsExams.numberLines', [7, 30])"
            />
          </div>

          <s-button
            class="box__action"
            size="large"
            @click="openEssayTheme()"
          >
            {{ $t('pages.assessmentsExams.viewEssayTheme') }}
          </s-button>
        </div>
      </Box>
    </transition-group>

    <TourAssessmentExams
      v-if="isTourActive"
      :application="application"
      @next="isNextButtonPressed = true"
      @previous="isPreviousButtonPressed = true"
      @skip="skipTour()"
    />

    <AssessmentExamModal
      v-if="isModalVisible && !hasDuration"
      :exam="exam"
      @close="hideExamModal()"
      @start="start($event)"
    />

    <AssessmentExamModal
      v-if="isModalVisible && hasDuration"
      :exam="exam"
      @close="hideExamModal()"
      @start="start($event)"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import axios from 'axios'

import Info from '@/components/Info'
import { getAuthToken } from '@/utils/auth'

const TourAssessmentExams = () => import('@/components/TourAssessmentExams')
const AssessmentExamModal = () => import('@/components/AssessmentExamModal')

export default {
  name: 'AssessmentExams',

  components: {
    Info,
    TourAssessmentExams,
    AssessmentExamModal,
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => vm.$emit('select-tab', vm.$route.name))
  },

  props: {
    assessmentId: {
      type: [String, Number],
      required: true,
    },
  },

  data() {
    return {
      isModalVisible: false,
      isPreviousButtonPressed: false,
      isNextButtonPressed: false,
      exam: null,
      languages: null,
      examsWithLanguage: null,
      selectedLanguages: [],
      loading: true,
      event: {
        category: 'Avaliação',
        action: 'DetalheProva',
        label: '',
      },
    }
  },

  computed: {
    ...mapGetters(['onboarding', 'application', 'exams', 'assessment']),

    resultDate() {
      return this.formatTime(this.assessment.resultDate)
    },

    hasDuration() {
      return this.exam.durationInMinutes > 0 && this.exam.hasDeadline
    },

    isTourActive() {
      return (
        this.onboarding.tourActive && !this.isLoading && this.exams.length > 0
      )
    },

    hasEssay() {
      if (this.assessment.hasEssay && this.assessment.essayInstructionsUrl) {
        return this.validateApplicationStart
      }

      return false
    },

    isLoading() {
      return this.loading
    },

    validateApplicationStart() {
      const currentDate = new Date()
      const applicationExamDates = this.exams.map((exam) => {
        const [aplicationStartDate] = (exam.dates || [])
          .filter((examDate) => examDate.type === 'APPLICATION_START_DATE')
        if (aplicationStartDate) {
          const { date } = aplicationStartDate

          return currentDate >= new Date(date).getTime()
        }

        return exam
      })

      return applicationExamDates.some((validDate) => validDate)
    },
    examIdsWithApplicationResponse() {
      return this.application.appointments
        .filter(({ status }) => ['DOING', 'DONE'].includes(status))
        .map(({ examId }) => examId)
    },
    isIOS() {
      return ['iPhone', 'iPod', 'iPad'].includes(navigator?.platform)
    },
  },

  async created() {
    this.$trackEvent(this.event)
    await this.load()
  },

  beforeDestroy() {
    const isTourButtonPressed = this.isNextButtonPressed
      || this.isPreviousButtonPressed
      || this.onboarding.helpTourButtonPressed
    if (this.isTourActive && !isTourButtonPressed) {
      this.setOnboarding({
        ...this.onboarding,
        tourActive: false,
        helpActive: true,
        helpTourButtonPressed: false,
      })
      this.$tours.onboarding.stop()
    }
  },

  methods: {
    ...mapActions([
      'setOnboarding',
      'startExam',
      'endExam',
      'getForeignLanguages',
      'getApplication',
      'getApplicationResponse',
      'getAssessment',
      'showMainLoading',
      'hideMainLoading',
    ]),

    async load() {
      this.loading = true
      this.setOnboarding({
        ...this.onboarding,
        helpTourButtonPressed: false,
      })
      this.languages = await this.getForeignLanguages()
      await this.getExamsWithLanguage()
      this.loading = false
    },

    async getExamsWithLanguage() {
      this.examsWithLanguage = this.exams.map((exam) => ({
        ...exam,
        selectedLanguage: null,
      }))

      try {
        const examsPromises = this.examsWithLanguage.map(async (exam) => {
          if (!this.examIdsWithApplicationResponse.includes(exam.id)) {
            return exam
          }

          const applicationResponse = await this.getApplicationResponse({
            applicationId: this.application.id,
            examId: exam.id,
          })

          if (!applicationResponse) {
            return exam
          }

          const {
            remainingTimeInSeconds,
            completed,
            deadline,
          } = applicationResponse

          const hasTime = remainingTimeInSeconds > 0 && deadline !== null

          if (deadline && !hasTime && !completed) {
            await this.endExam({
              applicationResponseId: applicationResponse.id,
            })
            this.load()
          }

          this.selectedLanguages.push({
            examId: exam.id,
            language: {
              code: applicationResponse.foreignLanguageCode,
              name: applicationResponse.foreignLanguageName,
            },
          })

          return {
            ...exam,
            hasTime,
            completed,
          }
        })

        this.examsWithLanguage = await Promise.all(examsPromises)
      } catch (err) {
        this.$toasted.global.error({
          message: 'Erro ao tentar carregar as provas.',
        })
      }
    },

    getExamDetails(exam) {
      const itemsSuffix = `${exam.itemsCount === 1 ? 'questão' : 'questões'}`

      return [
        {
          icon: 'calendar',
          text: this.getExamRange(exam),
        },
        {
          icon: 'check-square',
          text: `${exam.itemsCount} ${itemsSuffix}`,
        },
        {
          icon: 'clock',
          text: this.getExamDuration(exam.durationInMinutes),
        },
      ]
    },

    async openEssayTheme() {
      try {
        const token = getAuthToken()

        const config = {
          headers: {
            Authorization: token,
            'Content-Type': 'application/pdf',
          },
          responseType: 'blob',
        }
        const currentAssessment = this.assessment
        const { data } = await axios.get(currentAssessment.essayInstructionsUrl, config)
        const blob = new Blob([data], { type: 'application/pdf' })
        const documentName = currentAssessment?.name ?? 'arquivo'

        if (this.isIOS) {
          const reader = new FileReader()
          reader.onload = () => {
            window.location.href = reader.result
          }
          reader.filename = documentName
          reader.readAsDataURL(blob)
        } else {
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = documentName
          link.click()
        }
      } catch (err) {
        this.$toasted.global.error({
          message: 'Não foi possível baixar o arquivo. Tente novamente.',
        })
      }
    },

    getExamApplicationRange(exam) {
      const isFromCorrector = this.assessment.product === 'CORRECTOR'
      const { dates } = exam
      const startDate = dates.find(({ type }) => type === 'APPLICATION_START_DATE')
      const endDate = dates.find(({ type }) => type === 'APPLICATION_END_DATE')

      return {
        start: isFromCorrector ? startDate.date : exam.initialDate,
        end: isFromCorrector ? endDate.date : exam.finalDate,
      }
    },

    getExamRange(exam) {
      const { start, end } = this.getExamApplicationRange(exam)

      return `${this.formatTime(start)} até ${this.formatTime(end)}`
    },

    formatTime(time) {
      return this.$moment(time).format('L')
    },

    isNotAllowed(exam) {
      return exam.status === 'NOT_ALLOWED'
    },

    isExamAvaiable(exam) {
      return exam.status === 'TODO'
    },

    isExamStarted(exam) {
      return exam.status === 'DOING'
    },

    isExamDone(exam) {
      return exam.status === 'DONE'
    },

    isExamUncompleted(exam) {
      return exam.status === 'UNCOMPLETED'
    },

    isExamOutOfDate(exam) {
      return exam.status === 'OUT_OF_DATE'
    },

    hasBadge(exam) {
      return !this.isNotAllowed(exam) && !this.isExamAvaiable(exam)
    },

    isForeignLanguageVisible(exam) {
      return exam.canChooseForeignLanguage && !this.isExamDone(exam)
    },

    getSelectedLanguageName(exam) {
      const selected = this.selectedLanguages
        .find((item) => item.examId === exam.id)

      return selected
        ? selected.language.name : 'Idioma'
    },

    getBadge(exam) {
      if (this.isExamDone(exam) || exam.completed) {
        return {
          text: 'Concluída',
          status: 'success',
        }
      } if (this.isExamStarted(exam)) {
        return {
          text: 'Em andamento',
          status: 'warning',
        }
      } if (this.isExamOutOfDate(exam) || this.isExamUncompleted(exam)) {
        return {
          text: 'Prazo encerrado',
          status: 'danger',
        }
      }

      return {
        text: '',
        status: '',
      }
    },

    getExamDuration(durationInMinutes) {
      const hourDivider = 60
      const hours = Math.floor(durationInMinutes / hourDivider)
      const minutes = Math.floor(durationInMinutes % hourDivider)

      let duration = ''
      if (hours) {
        duration = duration.concat(`${hours}h`)
      }
      if (minutes) {
        const numberLength = 2

        duration = duration.concat(` ${minutes.toString().padStart(numberLength, 0)}min`)
      }

      return duration
    },

    getExamAction(exam) {
      if (this.isExamStarted(exam)) {
        if (!exam.hasTime && exam.deadline) {
          return {
            text: '',
            disabled: true,
            visible: false,
          }
        }

        return {
          text: 'Continuar a responder',
          disabled: false,
          visible: true,
        }
      } if (
        this.isNotAllowed(exam)
        || this.isExamUncompleted(exam)
        || this.isExamDone(exam)
        || this.isExamOutOfDate(exam)
      ) {
        return {
          text: '',
          disabled: true,
          visible: false,
        }
      } if (
        exam.canChooseForeignLanguage
        && !this.selectedLanguages.length > 0) {
        return {
          text: 'Iniciar Prova',
          disabled: true,
          visible: true,
        }
      }

      return {
        text: 'Iniciar Prova',
        disabled: false,
        visible: true,
      }
    },

    executeAction(exam) {
      this.showExamModal(exam)
    },

    showExamModal(exam) {
      this.exam = exam
      this.isModalVisible = true

      this.$trackEvent({
        ...this.event,
        action: 'ModalAvaliação',
      })
    },

    async start(exam) {
      const params = {
        applicationId: this.application.id,
        examId: exam.id,
      }

      const selectedLanguage = this.selectedLanguages
        .find((item) => item.examId === exam.id)

      if (selectedLanguage) {
        params.foreignLanguageCode = selectedLanguage.language.code
      }

      try {
        await this.startExam(params)
      } finally {
        this.hideExamModal()
      }
    },

    async finish(applicationResponseId) {
      this.showMainLoading()
      try {
        await this.endExam({ applicationResponseId })
        await this.getApplication(this.application.id)

        this.$router.replace({
          name: 'assessmentResults',
          params: {
            assessmentId: this.assessmentId,
            applicationId: this.application.id,
          },
        })
      } finally {
        this.hideMainLoading()
      }
    },

    hideExamModal() {
      this.isModalVisible = false
    },

    skipTour() {
      this.setOnboarding({
        ...this.onboarding,
        tourActive: false,
        helpActive: false,
      })
    },

    selectLanguage(exam, language) {
      const selectedLanguage = this.selectedLanguages
        .find((item) => item.examId === exam.id)

      if (selectedLanguage) {
        selectedLanguage.language = language
      } else {
        this.selectedLanguages.push({
          examId: exam.id,
          language,
        })
      }
    },

    isResultDateInfoVisible(exam) {
      return this.isExamDone(exam)
        && this.assessment.resultDate
        && this.assessment.product !== 'CORRECTOR'
    },
  },
}
</script>

<style lang="sass">

.assessemnt__warning
  margin-top: 16px

  .sas-info__label
    text-transform: none
    margin: 0
    color: #343C58
    font-size: 12px

  .badge
    margin-top: 32px
    background: transparentize($color-danger, .6) !important

    +mq-s
      margin-top: 24px

  .info
    grid-gap: 0px

    .info-right  p
      margin-left: 12px
      text-align: left

    .info-left .icon
      width: 16px
      height: 16px

.assessment__exams
  padding: 32px 0px 16px 0px
  width: 100%
  box-sizing: border-box
  display: grid
  grid-gap: 24px
  grid-template-columns: repeat(3, 1fr)
  padding-bottom: 48px

  +mq-l
    grid-template-columns: repeat(2, 1fr)
    grid-gap: 16px

  +mq-s
    padding-top: 24px
    grid-template-columns: 100%

  .exam
    min-height: 200px

  .exam__badge
    margin-top: 16px

  .exam__details
    display: flex
    flex-direction: column
    justify-content: space-between
    align-items: flex-start
    height: 100%

    &__infos
      margin: 16px 0

      &__label
        margin-bottom: 16px

        .sas-info__label
          text-transform: none
          font-size: 16px
          margin: 0
          color: #666E75 !important
          margin-left: $size-xs

    &.--flex-start
     justify-content: flex-start

    &.--exam-done
      height: auto

    .info
      margin-bottom: 16px
      background: white
      border-radius: 4px

  .exam__warning
    margin-top: 26px

    .info-right__label
      font-size: 14px

    .sas-info__label
      text-transform: none
      margin: 0

  .box__footer
    display: flex
    justify-content: space-between
    width: 100%

  .sas-dropdown
    width: 115px
    height: 44px
    margin-right: 5px

  .sas-button.--secondary
    height: 100%

</style>
